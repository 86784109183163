var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Thông tin nhân sự",
        visible: _vm.dialogVisible,
        width: "90%",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "3vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          staticStyle: { padding: "35px" },
          attrs: {
            model: _vm.formAllTabEmployee,
            "label-width": "130px",
            "label-position": "left",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 40 } },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "Họ tên", prop: "fullName" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.formAllTabEmployee.fullName,
                            callback: function ($$v) {
                              _vm.$set(_vm.formAllTabEmployee, "fullName", $$v)
                            },
                            expression: "formAllTabEmployee.fullName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "Ngày sinh", prop: "birthday" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            clearable: false,
                            format: "dd-MM-yyyy",
                            "picker-options": _vm.pickerOptions,
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            placeholder: "Chọn ngày sinh",
                          },
                          model: {
                            value: _vm.formAllTabEmployee.birthday,
                            callback: function ($$v) {
                              _vm.$set(_vm.formAllTabEmployee, "birthday", $$v)
                            },
                            expression: "formAllTabEmployee.birthday",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "gender", label: "Giới tính" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.formAllTabEmployee.gender,
                              callback: function ($$v) {
                                _vm.$set(_vm.formAllTabEmployee, "gender", $$v)
                              },
                              expression: "formAllTabEmployee.gender",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "Nam" } }, [
                              _vm._v("Nam"),
                            ]),
                            _c("el-radio", { attrs: { label: "Nữ" } }, [
                              _vm._v("Nữ"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "Số điện thoại", prop: "phone" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.formAllTabEmployee.phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.formAllTabEmployee, "phone", $$v)
                            },
                            expression: "formAllTabEmployee.phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "Ngày vào", prop: "startDate" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            type: "date",
                            format: "dd-MM-yyyy",
                            "value-format": "yyyy-MM-dd",
                            placeholder: "Chọn ngày vào",
                          },
                          model: {
                            value: _vm.formAllTabEmployee.startDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.formAllTabEmployee, "startDate", $$v)
                            },
                            expression: "formAllTabEmployee.startDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "Email", prop: "email" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.formAllTabEmployee.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.formAllTabEmployee, "email", $$v)
                            },
                            expression: "formAllTabEmployee.email",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "Số định danh", prop: "cmnd" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.formAllTabEmployee.cmnd,
                            callback: function ($$v) {
                              _vm.$set(_vm.formAllTabEmployee, "cmnd", $$v)
                            },
                            expression: "formAllTabEmployee.cmnd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "Ngày cấp", prop: "cmndDate" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            format: "dd-MM-yyyy",
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            placeholder: "Chọn ngày cấp",
                          },
                          model: {
                            value: _vm.formAllTabEmployee.cmndDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.formAllTabEmployee, "cmndDate", $$v)
                            },
                            expression: "formAllTabEmployee.cmndDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Địa chỉ thường trú",
                          prop: "permanentAddress",
                        },
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.formAllTabEmployee.permanentAddress,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabEmployee,
                                "permanentAddress",
                                $$v
                              )
                            },
                            expression: "formAllTabEmployee.permanentAddress",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 10 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "Chỗ ở hiện tại", prop: "address" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.formAllTabEmployee.address,
                            callback: function ($$v) {
                              _vm.$set(_vm.formAllTabEmployee, "address", $$v)
                            },
                            expression: "formAllTabEmployee.address",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "Hôn nhân", prop: "marriedStatus" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              clearable: "",
                              placeholder: "",
                            },
                            model: {
                              value: _vm.formAllTabEmployee.marriedStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formAllTabEmployee,
                                  "marriedStatus",
                                  $$v
                                )
                              },
                              expression: "formAllTabEmployee.marriedStatus",
                            },
                          },
                          _vm._l(_vm.options, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "Số con", prop: "numberChildren" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.formAllTabEmployee.numberChildren,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabEmployee,
                                "numberChildren",
                                $$v
                              )
                            },
                            expression: "formAllTabEmployee.numberChildren",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "Trình độ", prop: "educationLevel" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.formAllTabEmployee.educationLevel,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabEmployee,
                                "educationLevel",
                                $$v
                              )
                            },
                            expression: "formAllTabEmployee.educationLevel",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Đối tượng",
                          prop: "idAccountTypeList",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              multiple: "",
                              placeholder: "Chọn đối tượng",
                              clearable: "",
                              filterable: "",
                            },
                            model: {
                              value: _vm.formAllTabEmployee.idAccountTypeList,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formAllTabEmployee,
                                  "idAccountTypeList",
                                  $$v
                                )
                              },
                              expression:
                                "formAllTabEmployee.idAccountTypeList",
                            },
                          },
                          _vm._l(_vm.accountTypeList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { value: item.id, label: item.name },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Ngày ký hợp đồng",
                          prop: "contractDate",
                        },
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            type: "date",
                            format: "dd-MM-yyyy",
                            "value-format": "yyyy-MM-dd",
                            placeholder: "Ngày ký hợp đồng",
                          },
                          model: {
                            value: _vm.formAllTabEmployee.contractDate,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabEmployee,
                                "contractDate",
                                $$v
                              )
                            },
                            expression: "formAllTabEmployee.contractDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "Ngày hết hạn", prop: "endDate" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            type: "date",
                            format: "dd-MM-yyyy",
                            "value-format": "yyyy-MM-dd",
                            placeholder: "Ngày hết hạn",
                          },
                          model: {
                            value: _vm.formAllTabEmployee.endDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.formAllTabEmployee, "endDate", $$v)
                            },
                            expression: "formAllTabEmployee.endDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "Ghi chú", prop: "note" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: { type: "textarea", rows: 5 },
                          model: {
                            value: _vm.formAllTabEmployee.note,
                            callback: function ($$v) {
                              _vm.$set(_vm.formAllTabEmployee, "note", $$v)
                            },
                            expression: "formAllTabEmployee.note",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tab-infor-button" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }